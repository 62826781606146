import React from "react"
import AlertBar from "../components/alertBar"
import Layout from "../components/shared/layout"
import SEO from "../components/seo"
import ContactSection from "../components/shared/contact-section"
import ModalFormContact from "../components/modal-form-contact"
import Smile from "../images/smileImage.jpg"
import Text from "../text.json"
import "../components/styles/contact.scss"

const Contact = () => {
  const [modalShow, setModalShow] = React.useState(false)
  let pageDescription = "Notre équipe se fera un plaisir de répondre à toutes vos questions? Contacter nous des maintenant "
  return (
    <>
      <AlertBar french={true} />
      <Layout french={true}>
        <SEO title="Espace Candidat" description={pageDescription} lang="fr"/>
        <section className="contact__section">
          <ContactSection
            heading={Text["fr"].employees["section-1__heading"]}
            text={Text.fr.employees["section-1__text"]}
            textLink={Text.fr.employees["section-1__text-link"]}
            buttonText={Text.fr.employees["section-1__button"]}
            french={true}
            setModalShow={setModalShow}
          />
          <ContactSection
            image={Smile}
            heading={Text.fr.employees["section-2__heading"]}
            text={Text.fr.employees["section-2__text"]}
            buttonText={Text.fr.employees["section-1__button"]}
            styleClass={"contact__jumbo--white"}
            setModalShow={setModalShow}
          />
          <ModalFormContact
            french={true}
            formTitle={"Contact Form"}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </section>
      </Layout>
    </>
  )
}

export default Contact
